<template>
  <section class="invoice-preview-wrapper">
    <b-row class="invoice-preview">
      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="12"
        md="12"
      >
        <b-form @submit.prevent>
          <b-overlay
            :show="loadingCashierReport"
            rounded="sm"
          >
            <b-card
              no-body
              class="invoice-preview-card"
              ref="document"
              id="ordenCompra"
            >
              <!-- Header -->
              <b-card-body class="invoice-padding pb-0">

                <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

                  <!-- Header: Left Content -->
                  <div>
                    <div class="logo-wrapper">
                      <logo />
                      <h3 class="text-primary invoice-logo">
                        Reporte de balance de cajero
                      </h3>
                    </div>
                    <b-card-text class="mb-25">
                        <h6>Estatus: {{ cashierBalanceReport.status }}</h6>
                    </b-card-text>
                    <b-card-text class="mb-25">
                        Creado: {{ new Date(cashierBalanceReport.createdAt) | moment("DD/MM/YYYY, h:mm a") }}
                    </b-card-text>
                    <b-card-text class="mb-25">
                        Modificado: {{ new Date(cashierBalanceReport.modifiedAt) | moment("DD/MM/YYYY, h:mm a") }}
                    </b-card-text>
                    <b-card-text class="mb-25">
                        <h6>Dinero inicial: ${{ cashierBalanceReport.accRefillMoney }}</h6>
                    </b-card-text>
                    <b-card-text class="mb-25">
                        <h6>Dinero final: ${{ cashierBalanceReport.finalMoney }}</h6>
                    </b-card-text>
                    <b-button
                      :disabled="btnCloseReport"
                      v-if="cashierBalanceReport.status === 'OPENED'"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="primary"
                      class="mb-75"
                      block
                      @click="closeCashierBalanceReportApi()"
                    >
                        Cerrar reporte
                    </b-button>
                    <!-- <b-card-text class="mb-0">
                      +52 (811) 456 7891, +52 (551) 543 2198
                    </b-card-text> -->
                  </div>
                </div>
              </b-card-body>

              <!-- Spacer -->
              <hr class="invoice-spacing">

              <b-card-body
                class="invoice-padding pb-0"
              >
                <b-row>
                    <b-col
                            cols="12"
                            md="12"
                            class="mt-md-4 d-flex justify-content-center"
                    >
                        <div class="invoice-total-wrapper" style="max-width: 13rem">
                            <h6>Dinero Ingresado</h6>
                            <div class="invoice-total-item">
                                <p class="invoice-total-title">
                                    Denominacion
                                </p>
                                <p class="invoice-total-title">
                                    Cantidad
                                </p>
                            </div>
                            <div class="invoice-total-item" v-for="(money, index) in cashierBalanceReport.refillMoney" :key="index">
                                <p class="invoice-total-title">
                                    $ {{ money.denomination }}
                                </p>
                                <p class="invoice-total-amount">
                                    {{ money.quantity}}
                                </p>
                            </div>
                            <hr class="my-50">
                            <div class="invoice-total-item">
                                <p class="invoice-total-title">
                                    Total:
                                </p>
                                <p class="invoice-total-amount">
                                    $ {{ cashierBalanceReport.accRefillMoney }}
                                </p>
                            </div>
                        </div>
                    </b-col>
                </b-row>
                <b-row>
                  <b-col
                    cols="4"
                    md="4"
                    class="mt-md-4 d-flex justify-content-center"
                  >
                    <div class="invoice-total-wrapper" style="max-width: 13rem">
                      <h6>Dinero Actual</h6>
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          Denominacion
                        </p>
                        <p class="invoice-total-title">
                          Cantidad
                        </p>
                      </div>
                      <div class="invoice-total-item" v-for="(money, index) in cashierBalanceReport.currentMoney" :key="index">
                        <p class="invoice-total-title">
                          $ {{ money.denomination }}
                        </p>
                        <p class="invoice-total-amount">
                            {{ money.quantity}}
                        </p>
                      </div>
                      <hr class="my-50">
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          Total:
                        </p>
                        <p class="invoice-total-amount">
                          $ {{ cashierBalanceReport.accCurrentMoney }}
                        </p>
                      </div>
                    </div>
                  </b-col>

                  <b-col
                          cols="4"
                          md="4"
                          class="mt-md-4 d-flex justify-content-center"
                  >
                      <div class="invoice-total-wrapper" style="max-width: 13rem">
                          <h6>Dinero Dispensado</h6>
                          <div class="invoice-total-item">
                              <p class="invoice-total-title">
                                  Denominacion
                              </p>
                              <p class="invoice-total-title">
                                  Cantidad
                              </p>
                          </div>
                          <div class="invoice-total-item" v-for="(money, index) in cashierBalanceReport.dispensedMoney" :key="index">
                              <p class="invoice-total-title">
                                  $ {{ money.denomination }}
                              </p>
                              <p class="invoice-total-amount">
                                  {{ money.quantity}}
                              </p>
                          </div>
                          <hr class="my-50">
                          <div class="invoice-total-item">
                              <p class="invoice-total-title">
                                  Total:
                              </p>
                              <p class="invoice-total-amount">
                                  $ {{ cashierBalanceReport.accDispensedMoney }}
                              </p>
                          </div>
                      </div>
                  </b-col>

                  <b-col
                          cols="4"
                          md="4"
                          class="mt-md-4 d-flex justify-content-center"
                  >
                      <div class="invoice-total-wrapper" style="max-width: 13rem">
                          <h6>Dinero Rechazado</h6>
                          <div class="invoice-total-item">
                              <p class="invoice-total-title">
                                  Denominacion
                              </p>
                              <p class="invoice-total-title">
                                  Cantidad
                              </p>
                          </div>
                          <div class="invoice-total-item" v-for="(money, index) in cashierBalanceReport.rejectedMoney" :key="index">
                              <p class="invoice-total-title">
                                  $ {{ money.denomination }}
                              </p>
                              <p class="invoice-total-amount">
                                  {{ money.quantity}}
                              </p>
                          </div>
                          <hr class="my-50">
                          <div class="invoice-total-item">
                              <p class="invoice-total-title">
                                  Total:
                              </p>
                              <p class="invoice-total-amount">
                                  $ {{ cashierBalanceReport.accRejectedMoney }}
                              </p>
                          </div>
                      </div>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
          </b-overlay>
        </b-form>
      </b-col>

    </b-row>
  </section>
</template>


<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
      color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
background-color:$product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>


<script>
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import utils from '../../../utils/utils.js';
import { Spanish } from "flatpickr/dist/l10n/es.js"
flatpickr.localize(Spanish);
import Logo from '@core/layouts/components/Logo.vue'
import { ref, onUnmounted } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'


// temp
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

import { BTabs, BTab, BButton, BForm, BFormGroup, BSpinner, BCollapse,
BFormInput, BRow, BCol, BCard, BCardHeader, BCardTitle, BCardText,
BCardBody, BInputGroup, BInputGroupAppend, BInputGroupPrepend,
BFormCheckbox, VBTooltip, BAlert, BFormTextarea, BPopover, VBToggle, BOverlay } from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'



export default {
  components: {

    BAlert,
    BTabs,
    BTab,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormCheckbox,
    BCardBody,
    BFormTextarea,
    BPopover,
    flatPickr,
    vSelect,
    Logo,
    VueApexCharts,
    BSpinner,
    BOverlay,
    BCollapse
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  mixins: [heightTransition],
  data() {
    return {
      // Catalogs
      cashierBalanceReportId: undefined,
      cashierBalanceReport: {},
      btnCloseReport: false,
      items: [],
      companies: [],
      providers: [],
      companyData: {},
      paymentTypes: [],
      // Templates
      itemsTemplate: [],

      // Props
      showCreditDays: false,
      showPartialPayment: false,
      saveButtonStatus: false,
      byPassRouteLeave: true,
      loadingCashierReport: false,
      mailVoucherStatus: false,
      smsVoucherStatus: false,
      smsSucess: false,

      //
      configTime: {
        dateFormat: 'd-m-Y',
        locale: Spanish, // locale for this instance only
      },

      }
  },
  created() {
    this.cashierBalanceReportId = router.currentRoute.params.cashierBalanceReportId
    this.getCashierBalanceReportApi();
  },
  methods: {
    getCashierBalanceReportApi() {
      this.tableOverlay = true
      this.$axios.post(this.$services + "manager/getCashierBalanceReportApi", {
          cashierBalanceReportId: this.cashierBalanceReportId,
      }).then(res => {
          if (!res.data.error) {
              this.cashierBalanceReport = res.data;
          }
      }).catch(error => {
          if (typeof error != 'string') {
          } this.showToast('danger', String(error));
      }).finally(() => {
          this.tableOverlay = false
      });
    },
    closeCashierBalanceReportApi(){
        this.btnCloseReport = true;
        this.$axios.post(this.$services + "manager/closeCashierBalanceReportApi", {
            cashierBalanceReportId: this.cashierBalanceReport.cashierBalanceReportId,
        }).then(res => {
        }).catch(error => {
            if (typeof error != 'string') {
            } this.showToast('danger', String(error));
        }).finally(() => {
            this.getCashierBalanceReportApi()
            this.btnCloseReport = false;
        });
    },
    showModal() {

      if (this.checkMissingParameters()) {
        this.$bvModal.show('alertModal');
        this.saveButtonStatus = false;
        return;
      }

      this.$bvModal.show('confirmModal');
    },
  },
  computed: {
  },
}
</script>


<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  #add-item {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }

  .companyInfo {
    display: flex !important;
  }

  // Items
  #metal-select {
    flex: 0 0 auto;
    width: 50%;
  }

  .item-data {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  #spacer {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .item-commission {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

}
</style>
